import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "../../../../ui-treact/components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../../../ui-treact/components/misc/Buttons.js";
import UserIcon from "../svgs/UserIcon.js";
import TagIcon from "../svgs/TagIcon.js";
import ScrollAnimation from 'react-animate-on-scroll';
import "src/theme/styles/animate.min.css"; 
import Blog1 from 'src/images/blog1.jpeg'
import Blog2 from 'src/images/blog2.jpeg'
import Blog3 from 'src/images/blog3.jpeg'

// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
// import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

// const StyledDiv = styled.div``
const Container = tw.div`relative w-full`;
const Content = tw.div`max-w-screen-xl mx-auto py-8`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-8 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.image_src}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-black font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-800`;
// const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
//   ${tw`inline-block mt-4 text-sm font-semibold`}
// `

// const DecoratorBlob1 = tw(
//   StyledDiv
// )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
// const DecoratorBlob2 = tw(
//   StyledDiv
// )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Blog",
        heading: (
            <>We Love <span tw="text-primary-500">Writing.</span></>
          ),
        description: "Some amazing blog posts that are written by even more amazing people.",
        blogPosts: [
            {
              image_src:Blog1,
              author: "Adam Wathan",
              category: "SEO",
              title: "Optimizing your website for your main keyword",
              description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              url: "https://reddit.com"
            },
            {
              image_src:Blog2,
              author: "Owais Khan",
              category: "Advertising",
              title: "Creating The perfect advertisement campaign",
              description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              url: "https://timerse.com"
            },
            {
              image_src:Blog3,
              author: "Steve Schoger",
              category: "Social Media",
              title: "Efficient management of your social media assets",
              description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              url: "https://timerse.com"
            }
          ],
        ...finalData
  } 

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {content.sub_heading && <Subheading>{content.sub_heading}</Subheading>}
          <HeadingTitle>{content.heading}</HeadingTitle>
          <HeadingDescription>{content.description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {content.blogPosts.map((post, index) => (
            <Column key={index}>
            <ScrollAnimation animateIn='fadeInUp' delay={index*150} animateOnce={true}>
              <Card>
                <Image image_src={post.image_src} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Details>
              </Card>
            </ScrollAnimation>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};
